import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';

import {
	PRISM_DIALOG_DATA,
	PrismButtonComponent,
	PrismDialogComponent,
	PrismDialogRef
} from '@shure/prism-angular-components';

import { RefreshDialogData } from '../../../models/real-time-in-app-notification-events.interface';

@Component({
	selector: 'sh-refresh-dialog',
	standalone: true,
	imports: [CommonModule, PrismButtonComponent, PrismDialogComponent],
	templateUrl: './refresh-dialog.component.html',
	styleUrls: ['./refresh-dialog.component.scss']
})
export class RefreshDialogComponent {
	constructor(
		@Inject(PRISM_DIALOG_DATA) public data: RefreshDialogData,
		private dialogRef: PrismDialogRef<RefreshDialogComponent>
	) {}
	/**
	 * Closes the dialog and refreshes the page
	 */
	public onRefresh(): void {
		this.dialogRef.close();
		window.location.reload();
	}

	/**
	 * Closes the dialog without refreshing
	 */
	public onClose(): void {
		this.dialogRef.close();
	}
}
