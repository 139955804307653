import { asLabel, LanguageCode, LanguageDefinition } from '@shure/shared/models';

/**
 * List of default languages configured with transloco.
 */
export const DEFAULT_APP_LANGUAGES: LanguageDefinition[] = [
	{ id: LanguageCode.English, name: 'English', nameLabel: asLabel('preferences.language-settings.languages.en') },
	{ id: LanguageCode.German, name: 'Deutsch', nameLabel: asLabel('preferences.language-settings.languages.de') },
	{ id: LanguageCode.Spanish, name: 'Español', nameLabel: asLabel('preferences.language-settings.languages.es') },
	{ id: LanguageCode.French, name: 'Français', nameLabel: asLabel('preferences.language-settings.languages.fr') },
	{ id: LanguageCode.Italian, name: 'Italiano', nameLabel: asLabel('preferences.language-settings.languages.it') },
	{
		id: LanguageCode.Portuguese,
		name: 'Portuguese (Brazil)',
		nameLabel: asLabel('preferences.language-settings.languages.pt')
	},
	{ id: LanguageCode.Russian, name: 'Pусский', nameLabel: asLabel('preferences.language-settings.languages.ru') },
	{
		id: LanguageCode.ChineseSimple,
		name: '中文（简体) ',
		nameLabel: asLabel('preferences.language-settings.languages.zh-cn')
	},
	{
		id: LanguageCode.ChineseTraditional,
		name: '中國（傳統的) ',
		nameLabel: asLabel('preferences.language-settings.languages.zh-tw')
	},
	{ id: LanguageCode.Japanese, name: '日本語', nameLabel: asLabel('preferences.language-settings.languages.ja') },
	{ id: LanguageCode.Korean, name: '한국어', nameLabel: asLabel('preferences.language-settings.languages.ko') },
	{ id: LanguageCode.Arabic, name: 'العربية', nameLabel: asLabel('preferences.language-settings.languages.ar') }
];
