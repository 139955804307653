<prism-dialog id="sh-refresh-dialog-confirmation-prism-dialog" [visible]="true" modal="true">
	<span slot="dialog-title" class="sh-refresh-dialog-confirmation-dialog-title-span">{{ data?.title }}</span>
	<p id="sh-refresh-dialog-confirmation-dialog-message-p">{{ data?.message }}</p>
	<div slot="dialog-footer-buttons" id="sh-refresh-dialog-confirmation-dialog-actions-div">
		<prism-button id="sh-refresh-dialog-refresh-prism-button" variant="primary" (click)="onRefresh()">
			{{ data?.refreshText }}
		</prism-button>
		<prism-button id="sh-refresh-dialog-close-prism-button" (click)="onClose()">
			{{ data?.closeText }}
		</prism-button>
	</div>
</prism-dialog>
