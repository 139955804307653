import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton, MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { EMPTY, Observable, of, Subject, takeUntil } from 'rxjs';

import {
	NotificationsEndpointApiService,
	PostNotificationsRequest
} from '@shure/cloud/shared/data-access/notifications';
import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';
import {
	CloseTextOption,
	formatToUserSpecificTime,
	LoaderModule,
	SnackbarService
} from '@shure/cloud/shared/ui/components';
import { CloudNavigationService } from '@shure/cloud/shared/utils/navigation';
import { PrismIconButtonComponent, PrismListComponent, PrismListItemComponent } from '@shure/prism-angular-components';

import { CHUNK_SIZE } from '../../../models/real-time-in-app-notification-events.interface';
import { NotificationDetailsComponent } from '../notification-details/notification-details.component';
export enum NotificationFilterEnum {
	READ = 'read',
	UNREAD = 'unread',
	ARCHIVED = 'archived',
	TRASHED = 'trashed'
}

@Component({
	selector: 'sh-notifications-popup',
	standalone: true,
	imports: [
		CommonModule,
		MatDividerModule,
		MatListModule,
		MatSlideToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatTabsModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		TranslocoModule,
		MatIcon,
		MatIconButton,
		MatBadge,
		LoaderModule,
		MatButtonModule,
		MatDivider,
		PrismIconButtonComponent,
		PrismListComponent,
		PrismListItemComponent,
		NotificationDetailsComponent,
		MatIconModule,
		MatTooltipModule
	],
	templateUrl: './notifications-popup.component.html',
	styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
	public application: string;
	public notifications$: Observable<NotificationStoreData[] | null> = EMPTY;
	public currentDate = new Date();
	public isApiCallFailed = false;
	public successApiCallCount = 0;
	public readonly destroy$ = new Subject<void>();
	public notificationsData: NotificationStoreData[] = [];
	public nextPageToken?: string;
	public totalNotificationsCount = 0;
	public unReadNotiificationCalled = false;

	constructor(
		public notificationsStore: NotificationsStore,
		public notificationService: NotificationsEndpointApiService,
		public dialogRef: MatDialog,
		public router: Router,
		public cdr: ChangeDetectorRef,
		private translocoService: TranslocoService,
		private snackBarService: SnackbarService,
		private navigationService: CloudNavigationService,
		@Inject(MAT_DIALOG_DATA) public data: { application?: string }
	) {
		this.application = <string>data?.application;
	}

	/**
	 * Initializes the component and subscribe to the notifications store.
	 */
	public ngOnInit(): void {
		// Subscribe to the notifications data
		this.notificationsStore.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			this.notificationsData = notifications;
			const unreadNotifications = notifications.filter((n) => !n.readAt && !n.archivedAt);
			this.notifications$ = of(
				unreadNotifications.map((item) => ({
					...item,
					body: item.body?.replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' '),
					sentAt: item.sentAt ? formatToUserSpecificTime(item.sentAt) : ''
				}))
			);
			if (this.nextPageToken && this.totalNotificationsCount <= 500) {
				this.loadUnreadNotifications(this.nextPageToken);
			}
			this.cdr.detectChanges();
		});
		this.loadUnreadNotifications();
	}

	/**
	 * Fetches notifications from the server and updates the notification store.
	 */
	public loadUnreadNotifications(nextPageToken?: string): void {
		const params = {
			application: this.application,
			next: nextPageToken,
			filter: NotificationFilterEnum.UNREAD
		};

		this.notificationService
			.getNotifications$Response(params)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (notificationResponse) => {
					if (notificationResponse?.body?.items) {
						this.nextPageToken = notificationResponse?.body?.next;
						const unreadNotifications = notificationResponse.body.items;
						this.notificationsStore.prependOrPatchNotifications(
							<NotificationStoreData[]>(<unknown>unreadNotifications)
						);
						this.totalNotificationsCount = this.totalNotificationsCount + unreadNotifications.length;
						this.unReadNotiificationCalled = true;
					}
				},
				error: () => {
					this.isApiCallFailed = true;
				}
			});
	}

	/**
	 * Closes all dialogs and navigates to the notifications details page.
	 */
	public notificationDetails(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('notifications-details');
	}

	/**
	 * Returns the current date in UTC format as a string.
	 */
	public getCurrentDateInUTC(): string {
		const currentDate = new Date();
		const currentdateFormat = new Date(currentDate.getTime() + 10000);
		return currentdateFormat.toISOString().slice(0, 19) + 'Z';
	}

	/**
	 * Opens a dialog with details of the selected notification.
	 * @param notification The notification to display details for
	 */
	public async openPopup(notification: NotificationStoreData): Promise<void> {
		this.dialogRef.closeAll();
		const selectedNotification = this.notificationsData.find((item) => item.id === notification.id);
		// Define the notificationId based on the type of notification passed

		this.dialogRef.open(NotificationDetailsComponent, {
			width: '500px',
			data: { notificationData: selectedNotification, isListPage: false }
		});
	}

	/**
	 * Marks all unread notifications as read.
	 */
	public clearAll(): void {
		let displayedNotifications: NotificationStoreData[] = [];
		this.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			displayedNotifications = <NotificationStoreData[]>(<unknown>notifications);
		});
		const displayedNotificationsIds = displayedNotifications.map(
			(notification: NotificationStoreData) => notification?.id
		);

		for (let i = 0; i < displayedNotificationsIds.length; i += CHUNK_SIZE) {
			const chunk = displayedNotificationsIds.slice(i, i + CHUNK_SIZE);
			const requestData = {
				body: chunk.map((notificationId) => ({
					id: notificationId,
					isRead: true
				}))
			};
			const readNotifications = chunk.map((notificationId) => ({
				id: notificationId,
				readAt: this.getCurrentDateInUTC()
			}));

			if (!this.isApiCallFailed) {
				this.updateNotifications(requestData, readNotifications);
			} else {
				this.isApiCallFailed = false;
				break;
			}
		}
	}

	public markAsRead(notificationData: NotificationStoreData): void {
		this.successApiCallCount = 0;
		const requestData = {
			body: [
				{
					id: notificationData.id,
					isRead: true
				}
			]
		};
		const readNotification = [
			{
				id: notificationData.id,
				readAt: this.getCurrentDateInUTC()
			}
		];
		this.updateNotifications(requestData, readNotification);
	}

	/**
	 * Post API call for updating notifications status.
	 */
	public updateNotifications(
		requestData: { body: PostNotificationsRequest },
		updatedNotifications: Array<Pick<NotificationStoreData, 'id'> & Partial<NotificationStoreData>>
	): void {
		this.notificationService
			.postNotifications(requestData)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.successApiCallCount += 1;
					this.notificationsStore.prependOrPatchNotifications(updatedNotifications);
				},
				error: () => {
					this.isApiCallFailed = true;
					if (this.successApiCallCount === 0) {
						this.snackBarService.open(
							this.translocoService.translate('cloud.shared.notification.unable-to-mark-as-read'),
							CloseTextOption.Dismiss
						);
					} else {
						this.snackBarService.open(
							this.translocoService.translate(
								'cloud.shared.notification.unable-to-mark-some-notifications-as-read'
							),
							CloseTextOption.Dismiss
						);
					}
				}
			});
	}

	/**
	 * Closes all open dialogs.
	 */
	public closedialog(): void {
		this.dialogRef.closeAll();
	}

	/**
	 * Closes all dialogs and navigates to the notifications list page.
	 */
	public notificationsList(): void {
		this.dialogRef.closeAll();
		this.navigationService.toShureCloudUrl('/notifications-list');
	}

	/**
	 * Performs cleanup when the component is destroyed.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
