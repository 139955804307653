<ng-container *transloco="let t">
	<div id="sh-notification-popup-header-div">
		<div id="sh-notification-popup-content-div">
			<div id="sh-notification-popup-text-div">
				<p id="sh-notification-popup-title-p">{{ t('cloud.shared.notification.title') }}</p>
				<p id="sh-notification-popup-current-date-p">{{ currentDate | date: 'MMMM d, y' }}</p>
			</div>
			<mat-icon
				id="sh-notification-popup-close-icon"
				fontSet="prism-mat"
				fontIcon="close"
				(click)="closedialog()"
			></mat-icon>
		</div>
		<div id="sh-notification-popup-buttons-div" class="sh-notification-popup-buttons">
			<button mat-button color="accent" (click)="notificationsList()" id="sh-notification-popup-view-all-button">
				{{ t('cloud.shared.notification.view-all') }}
			</button>
			<button
				[disabled]="(notifications$ | async)?.length === 0"
				mat-button
				color="accent"
				(click)="clearAll()"
				id="sh-notification-popup-close-button"
			>
				{{ t('cloud.shared.notification.mark-all-as-read') }}
			</button>
		</div>
	</div>
	<p
		id="sh-notification-popup-list-loading-message-p"
		class="sh-notification-popup-loading-style"
		*ngIf="(notifications$ | async) === null"
	>
		{{ t('cloud.shared.notification.loading') }}
	</p>
	<div id="sh-notification-popup-list-container-div" class="sh-notification-popup-list-container">
		@if ((notifications$ | async)?.length !== 0) {
			<prism-list id="sh-notification-list">
				@for (notification of notifications$ | async; track notification; let i = $index) {
					<prism-list-item
						label="list-item"
						class="sh-notification-popup-list-item"
						id="sh-notification-popup-popup-list-item-{{ i }}"
					>
						<div id="sh-notification-popup-list-item-div" class="sh-notification-popup-list-item-div">
							<div id="sh-notification-popup-dot-div" class="sh-notification-popup-dot"></div>
							<div
								id="sh-notification-popup-list-content-div"
								class="sh-notification-popup-list-content"
								(click)="openPopup(notification)"
							>
								<div
									id="sh-notification-popup-organization-name-div"
									class="sh-notification-popup-organization-name"
								>
									{{ t('cloud.shared.notification.organization') | uppercase }}:
									{{ notification?.organization?.name | uppercase }}
								</div>
								<div id="sh-notification-popup-subject-div" class="sh-notification-popup-subject">
									{{ notification.subject }}
								</div>
								<div id="sh-notification-popup-body-div" class="sh-notification-popup-body">
									{{ notification.body }}
								</div>
							</div>
							<button
								mat-icon-button
								class="sh-notification-popup-clear-icon"
								id="sh-notification-popup-popup-clear-icon-{{ i }}"
								(click)="markAsRead(notification)"
								matTooltip="{{ t('common.buttons.clear') }}"
							>
								<mat-icon fontSet="prism-mat" fontIcon="remove_circle_outline"></mat-icon>
							</button>
							<div
								id="sh-notification-popup-custom-date-div"
								mat-line
								class="sh-notification-popup-custom-date"
							>
								{{ notification.sentAt }}
							</div>
						</div>
					</prism-list-item>
				}
			</prism-list>
		}
	</div>
	@if ((notifications$ | async)?.length === 0) {
		@if (unReadNotiificationCalled) {
			<div id="sh-notification-popup-no-notification">
				<p id="sh-notification-popup-no-notification-text">
					{{ t('cloud.shared.notification.no-notification') }}
				</p>
			</div>
		} @else {
			<div id="sh-notification-popup-loading-div">
				<sh-loader></sh-loader>
			</div>
		}
	}
</ng-container>
<mat-divider></mat-divider>
