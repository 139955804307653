import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import { DeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/core';
import { DeviceImage, DeviceImageBehaviorPlugin } from '@shure/shared/angular/device-behavior/plugins/device-image';

/**
 * MXW1X is the model name of the bodypack transmitter for APXD2
 * `Mxw1xDeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export class Mxw1xDeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXW1X];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXW1X:
				return {
					lightsBackground: 'missing-in-device-plugin',
					live: 'device-mxwn-bp.svg',
					virtual: 'missing-in-device-plugin',
					liveBack: 'missing-in-device-plugin',
					virtualBack: 'missing-in-device-plugin'
				};
			default:
				return undefined;
		}
	}
}
