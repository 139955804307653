import { Inject, Injectable, signal, ViewContainerRef } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { PrismDialogService } from '@shure/prism-angular-components';

import { RTUData } from '../models/real-time-in-app-notification-events.interface';
import { RefreshDialogComponent } from '../ui/dialogs/refresh-dialog/refresh-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class RealTimeInAppBrowserNotificationService {
	public ws: WebSocket | null = null;
	public retryCount = 0;
	public setPreferencesFlag = true;
	public isWebSocketConnected = signal<boolean>(false);
	public retryDelay = 2000; // 2 seconds
	public vcr!: ViewContainerRef;

	private maxRetries = 3;
	private connectionCheckInterval = 5000; // Check every 5 seconds
	private connectionCheckTimer?: ReturnType<typeof setTimeout>;
	private token = '';
	private application: string | undefined = '';
	private notificationsWebSocketUrl: string | undefined = '';

	constructor(
		private notificationsStore: NotificationsStore,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,
		private readonly prismDialogService: PrismDialogService,
		private translocoService: TranslocoService
	) {}

	// Connect to the WebSocket with given credentials
	public connectWebSocket({
		token,
		application,
		notificationsWebSocketUrl
	}: {
		token: string;
		application?: string;
		notificationsWebSocketUrl?: string;
	}): void {
		this.token = token;
		this.application = application;
		this.notificationsWebSocketUrl = notificationsWebSocketUrl;

		// Ensure application id and WebSocket URL are provided
		if (!(this.application && this.notificationsWebSocketUrl)) {
			// eslint-disable-next-line no-console
			console.log('No application id or WebSocket URL was provided, hence cannot connect to the WebSocket');
			return;
		}

		// Construct WebSocket URL with query parameters
		const url = new URL(this.notificationsWebSocketUrl);
		url.searchParams.append('application', this.application);
		url.searchParams.append('Authorization', `Bearer ${this.token}`);

		this.disconnectWebSocket(); // Close existing connection if any
		this.ws = new WebSocket(url.toString());
		this.attachWebSocketHandlers(); // Setup event handlers
	}

	// Disconnect from the WebSocket
	public disconnectWebSocket(): void {
		if (this.ws) {
			this.ws.close(1000); // Close with normal closure code
			this.ws = null;
		}
		this.stopConnectionCheck(); // Stop checking the connection
	}

	/**
	 * Sets the ViewContainerRef from the calling component.
	 * This is required to render dialogs from the service,
	 * since ViewContainerRef is only available in component/directive contexts.
	 *
	 * @param vcr - The ViewContainerRef instance from a component
	 */
	public setViewContainerRef(vcr: ViewContainerRef): void {
		this.vcr = vcr;
	}

	// Handle and process incoming WebSocket messages
	private handleEvent(rtuData: RTUData): void {
		if (rtuData?.data) {
			if (rtuData.data.appName === this.appEnv.application && rtuData?.data.type === 'SWITCH') {
				this.showUpdateAvailableDialog();
			}
			if (rtuData?.data.isSetPreferences) {
				if (this.setPreferencesFlag) {
					this.notificationsStore.prependOrPatchNotifications(<NotificationStoreData[]>(
						(<unknown>[rtuData.data])
					));
				}
				this.setPreferencesFlag = false;
			} else {
				this.notificationsStore.prependOrPatchNotifications(<NotificationStoreData[]>(<unknown>[rtuData.data]));
			}
		}
	}

	// Setup WebSocket event handlers
	private attachWebSocketHandlers(): void {
		if (!this.ws) return;

		this.ws.onopen = (): void => {
			this.isWebSocketConnected.set(true);
			this.retryCount = 0; // Reset retry count when connected
			this.startConnectionCheck(); // Start monitoring the connection
		};

		this.ws.onclose = (e: CloseEvent): void => {
			this.isWebSocketConnected.set(false);
			this.stopConnectionCheck(); // Stop monitoring on close
			if (e.code !== 1000) {
				// eslint-disable-next-line no-console
				console.log('WebSocket closed. Attempting to reconnect...', e);
				this.retryConnection(); // Try to reconnect if not normal closure
			} else {
				// eslint-disable-next-line no-console
				console.log('WebSocket closed normally. No reconnection will be attempted.');
			}
		};

		this.ws.onerror = (e: Event): void => {
			this.isWebSocketConnected.set(false);
			// eslint-disable-next-line no-console
			console.error('WebSocket error observed:', e);
			this.retryConnection(); // Try to reconnect on error
		};

		this.ws.onmessage = (e: MessageEvent): void => {
			try {
				const eventData = JSON.parse(e.data);
				this.handleEvent(eventData); // Handle the incoming message
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error parsing incoming Websocket message:', error);
			}
		};
	}

	// Attempt to reconnect to the WebSocket
	private retryConnection(): void {
		if (this.retryCount >= this.maxRetries) {
			// eslint-disable-next-line no-console
			console.log('Maximum retries reached for Websocket. No further attempts will be made.');
			return;
		}
		this.retryCount++;
		setTimeout(() => {
			if (this.token) {
				this.connectWebSocket({
					token: this.token,
					application: this.application,
					notificationsWebSocketUrl: this.notificationsWebSocketUrl
				});
			}
		}, this.retryDelay);
	}

	// Begin periodic checks of the WebSocket connection
	private startConnectionCheck(): void {
		this.connectionCheckTimer = setInterval(() => {
			if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
				// eslint-disable-next-line no-console
				console.log('Websocket Connection check failed. Trying to reconnect...');
				this.retryConnection();
			}
		}, this.connectionCheckInterval);
	}

	// Stop periodic checks of the WebSocket connection
	private stopConnectionCheck(): void {
		if (this.connectionCheckTimer) {
			clearInterval(this.connectionCheckTimer);
		}
	}

	/**
	 * Opens the "Update Available" dialog to notify the user
	 * that a new version or environment update is available.
	 * This is typically triggered when a 'SWITCH' event is received via WebSocket.
	 */
	private showUpdateAvailableDialog(): void {
		this.prismDialogService.openDialog(RefreshDialogComponent, this.vcr, {
			data: {
				message: this.translocoService.translate('cloud.shure-cloud.environment-switch-update-message'),
				closeText: this.translocoService.translate('cloud.shure-cloud.close'),
				refreshText: this.translocoService.translate('cloud.shure-cloud.refresh'),
				title: this.translocoService.translate('cloud.shure-cloud.environment-switch-update-title')
			}
		});
	}
}
