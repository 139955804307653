import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import { DeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/core';
import { DeviceImage, DeviceImageBehaviorPlugin } from '@shure/shared/angular/device-behavior/plugins/device-image';

/**
 * `AdtqDeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export class AdtqDeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.ADTQ];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.ADTQ:
				return {
					lightsBackground: 'device-adtq.svg',
					live: 'device-adtq.svg',
					virtual: 'device-adtq.svg',
					liveBack: 'device-adtq.svg',
					virtualBack: 'device-adtq.svg'
				};
			default:
				return undefined;
		}
	}
}
