import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import { DeviceBehaviorPlugin } from '@shure/shared/angular/device-behavior/core';
import { DeviceImage, DeviceImageBehaviorPlugin } from '@shure/shared/angular/device-behavior/plugins/device-image';

/**
 * `Mxa710DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export class Mxa710DeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXA7102FT, DeviceModel.MXA7104FT];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXA7102FT:
				return {
					lightsBackground: 'missing-in-device-plugin',
					live: 'device-mxa710-2ft.svg',
					virtual: 'device-mxa710-2ft-line.svg',
					liveBack: 'missing-in-device-plugin',
					virtualBack: 'missing-in-device-plugin'
				};
			case DeviceModel.MXA7104FT:
				return {
					lightsBackground: 'missing-in-device-plugin',
					live: 'device-mxa710-4ft.svg',
					virtual: 'device-mxa710-4ft-line.svg',
					liveBack: 'missing-in-device-plugin',
					virtualBack: 'missing-in-device-plugin'
				};
			default:
				return undefined;
		}
	}
}
